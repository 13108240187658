<template>
<div>
  <b-row>
    <b-col cols=5 lg=4 class="ml-1">
        <b-form-group
          id="input-group-1"
          label="Traif horaire"
          label-for="input-1"
          description="Prix à l'heure (€/h)"
          v-show="tarif_kwh==null | tarif_kwh==''"
        >
          <b-input-group size="md" append="€/h">
            <b-form-input
              id="input-1"
              v-model="tarif_horaire"
              type="number"
              step="0.001"
              @change="get_tarif">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Tarif au kWh"
          label-for="input-2"
          description="Prix au kWh (€/kWh)"
          v-show="tarif_horaire==null | tarif_horaire==''"
        >
          <b-input-group size="md" append="€/kWh">
            <b-form-input
              id="input-2"
              v-model="tarif_kwh"
              type="number"
              step="0.001"
              @change="get_tarif">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          label="Temps de recharge (h)"
          label-for="input-4"
          :description="temps_charge_complet!=null ? 'Recharge complète en ' + temps_charge_complet.toFixed(2) + 'h' : 'Temps de recharge en h'"
        >
          <b-input-group size="md" append="h">
            <b-form-input
              id="input-4"
              v-model="loading_time"
              type="number"
              step="0.1"
              @change="get_tarif">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          label="Puissance :"
          label-for="input-4"
          :description="localparam.loading != null ? 'Puissance de recharge (maximum : '+localparam.loading+' kW)' : 'Puissance de recharge (kW)'"
        >
          <b-input-group size="md" append="kW">
            <b-form-input
              id="input-4"
              v-model="puissance"
              type="number"
              step="0.1"
              @change="get_tarif">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          id="input-group-5"
          label="Prix recharge"
          label-for="input-5"
          description="Tarif de la recharge (€)"
        >
          <b-input-group size="md" append="€">
            <b-form-input
              id="input-5"
              v-model="tarif_recharge"
              type="number"
              step="0.01"
              @change="get_tarif">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          id="input-group-6"
          label="Autonomie restante"
          label-for="input-6"
          description="Autonomie restante (%)"
        >
          <b-input-group size="md" append="%">
            <b-form-input
              id="input-6"
              v-model="pct_charge_restante"
              type="number"
              step="1"
              @change="get_tarif">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- <b-button variant="primary" @click.prevent="get_tarif">Calculer</b-button> -->
    </b-col>
    <b-col cols=6 lg=4>
      <p v-if="tarif_equivalent!=null">Equivalent tarif essence : <br/>{{tarif_equivalent.toFixed(3) }} €/l</p>
      <p v-if="autonomie_gagnee!=null">Autonomie gagnée / finale : <br/>{{autonomie_gagnee.toFixed(0)}} km --> {{autonomie_finale.toFixed(0)}} km</p>
      <p v-if="pct_charge!=null">Charge de la batterie ajoutée / finale : <br/>{{pct_charge.toFixed(0)}} % --> {{pct_charge_final.toFixed(0)}} %</p>
      <p v-if="prix_recharge!=null">Cout de la recharge  : <br/>{{prix_recharge.toFixed(2)}} €</p>
    </b-col>
  </b-row>
  <b-button v-b-modal.modal-1>Paramètres</b-button>
  <b-modal id="modal-1" title="Parametres" ok-only ok-title="Fermer">
    <Params @param="localparam" @param_set="set_param"/>
  </b-modal>
  </div>
</template>

<script>
import Params from '@/components/Params.vue'

export default {
  components: {
    Params
  },
  data () {
    return {
      localparam: {
        battery: null,
        loading: null,
        consumption_elec: null,
        consumption_fuel: null
      },
      tarif_horaire: null,
      tarif_kwh: null,
      puissance: null,
      tarif_recharge: 0,
      loading_time: null,
      pct_charge_restante: 0
    }
  },
  methods: {
    get_tarif (event) {
      // if (this.tarif_horaire != null) {
      //   this.tarif_equivalent = this.localparam.consumption_elec / this.localparam.loading * this.tarif_horaire
      //   // if (this.loading_time != null) {
      //   //   this.prix_recharge = this.loading_time * this.tarif_horaire / 1 + (this.tarif_recharge == null ? 0 : this.tarif_recharge) / 1
      //   // }
      // } else if (this.tarif_kwh != null) {
      //   this.tarif_equivalent = this.localparam.consumption_elec / this.localparam.tarif_kwh * this.tarif_horaire
      //   // if (this.loading_time != null) {
      //   //   this.prix_recharge = Math.min((this.localparam.loading * this.loading_time), this.localparam.battery) * this.tarif_kwh / 1 + (this.tarif_recharge == null ? 0 : this.tarif_recharge) / 1
      //   // }
      // } else {
      //   this.tarif_equivalent = null
      //   // this.prix_recharge = null
      // }
      // if (this.loading_time != null) {
      //   // this.autonomie_gagnee = Math.min((this.localparam.loading * this.loading_time), this.localparam.battery) / this.localparam.consumption_elec * 100
      //   // this.pct_charge = Math.min((this.localparam.loading * this.loading_time), this.localparam.battery) / this.localparam.battery * 100
      // } else {
      //   // this.prix_recharge = null
      //   // this.pct_charge = null
      // }
    },
    set_param (newParam) {
      this.localparam = Object.assign({}, newParam)
      this.$bvModal.hide('modal-1')
      this.get_tarif()
    },
    formatPrice (value, decimal = 0) {
      const val = (value / 1).toFixed(decimal)
      return val
    }
  },
  computed: {
    puissance_recharge () {
      if (this.puissance != null && this.localparam.loading != null) {
        return Math.min(parseFloat(this.localparam.loading), parseFloat(this.puissance))
      } else if (this.puissance != null) {
        return parseFloat(this.puissance)
      } else if (this.localparam.loading != null) {
        return parseFloat(this.localparam.loading)
      }
      return null
    },
    temps_charge_complet () {
      if (this.puissance_recharge != null && this.localparam.battery != null && this.localparam.battery !== '') {
        return (1 - parseFloat(this.pct_charge_restante) / 100.0) * parseFloat(this.localparam.battery) / this.puissance_recharge
      }
      return null
    },
    charge_kwh () {
      if (this.loading_time != null && this.localparam.battery != null && this.puissance_recharge != null && this.loading_time !== '' && this.localparam.battery !== '') {
        return Math.min((this.puissance_recharge * parseFloat(this.loading_time)), parseFloat(this.localparam.battery) * (1 - parseFloat(this.pct_charge_restante) / 100.0))
      }
      return null
    },
    pct_charge () {
      if (this.charge_kwh != null && this.localparam.battery != null && this.localparam.battery !== '') {
        return this.charge_kwh / parseFloat(this.localparam.battery) * 100.0
      }
      return null
    },
    pct_charge_final () {
      if (this.pct_charge != null) {
        return this.pct_charge + parseFloat(this.pct_charge_restante)
      }
      return null
    },
    prix_recharge () {
      if (this.loading_time != null && this.loading_time !== '') {
        if (this.tarif_horaire != null && this.tarif_horaire !== '') {
          return parseFloat(this.loading_time) * parseFloat(this.tarif_horaire) / 1.0 + parseFloat(this.tarif_recharge) / 1.0
        } else if (this.tarif_kwh != null && this.tarif_kwh !== '') {
          return this.charge_kwh * parseFloat(this.tarif_kwh) + parseFloat(this.tarif_recharge)
        }
      }
      return null
    },
    autonomie_gagnee () {
      if (this.charge_kwh != null && this.localparam.consumption_elec != null && this.localparam.consumption_elec !== '') {
        return this.charge_kwh / parseFloat(this.localparam.consumption_elec) * 100.0
      }
      return null
    },
    autonomie_finale () {
      if (this.charge_kwh != null && this.localparam.consumption_elec != null && this.localparam.battery != null && this.localparam.consumption_elec !== '' && this.localparam.battery !== '') {
        return (this.charge_kwh + parseFloat(this.pct_charge_restante) * parseFloat(this.localparam.battery) / 100.0) / parseFloat(this.localparam.consumption_elec) * 100.0
      }
      return null
    },
    tarif_equivalent () {
      if (this.prix_recharge != null && this.autonomie_gagnee != null) {
        return this.prix_recharge / this.autonomie_gagnee * 100 / parseFloat(this.localparam.consumption_fuel)
      } else if (this.tarif_horaire != null && this.tarif_horaire !== '') {
        return parseFloat(this.localparam.consumption_elec) / parseFloat(this.localparam.consumption_fuel) / this.puissance_recharge * parseFloat(this.tarif_horaire)
      } else if (this.tarif_kwh != null && this.tarif_kwh !== '') {
        return parseFloat(this.localparam.consumption_elec) * parseFloat(this.tarif_kwh) / parseFloat(this.localparam.consumption_fuel)
        // if (this.loading_time != null) {
        //   this.prix_recharge = Math.min((this.localparam.loading * this.loading_time), this.localparam.battery) * this.tarif_kwh / 1 + (this.tarif_recharge == null ? 0 : this.tarif_recharge) / 1
        // }
      }
      return null
    }
  },
  mounted () {
    let params = localStorage.getItem('hybrid_params')
    if (params != null) {
      params = JSON.parse(params)
      this.localparam = Object.assign({}, params)
    }
  }
}
</script>

<style>

</style>
