<template>
  <b-col cols="12" lg="6">
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group
        id="input-group-1"
        label="Batterie :"
        label-for="input-1"
        description="Capacité de la batterie en (kWh)"
      >
        <b-input-group size="md" append="kWh">
          <b-form-input
            id="input-1"
            v-model="localparam.battery"
            type="number"
            step="0.1"
            placeholder="100"
            required>
          </b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="Charge :"
        label-for="input-2"
        description="Puissance de charge en kW"
      >
        <b-input-group size="md" append="kW">
          <b-form-input
            id="input-2"
            v-model="localparam.loading"
            type="number"
            step="0.1"
            placeholder="5.5"
            required>
          </b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        label="Consommation elec :"
        label-for="input-4"
        description="Consommation électrique (kWh/100km)"
      >
        <b-input-group size="md" append="kWh/100km">
          <b-form-input
            id="input-3"
            v-model="localparam.consumption_elec"
            type="number"
            step="0.1"
            placeholder="30"
            required>
          </b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group
        id="input-group-4"
        label="Consommation essence :"
        label-for="input-4"
        description="Consommation d'essence (l/100km)"
      >
        <b-input-group size="md" append="l/100km">
          <b-form-input
            id="input-4"
            v-model="localparam.consumption_fuel"
            type="number"
            step="0.1"
            placeholder="10"
            required>
          </b-form-input>
        </b-input-group>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
  </b-col>
</template>

<script>
export default {
  props: {
    param: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      localparam: {
        battery: null,
        loading: null,
        consumption_elec: null,
        consumption_fuel: null
      }
    }
  },
  methods: {
    onSubmit (event) {
      localStorage.setItem('hybrid_params', JSON.stringify(this.localparam))
      event.preventDefault()
      this.$emit('param_set', this.localparam)
      // alert(JSON.stringify(this.param));
    },
    onReset (event) {
      let params = localStorage.getItem('hybrid_params')
      if (params != null) {
        params = JSON.parse(params)
        this.localparam = Object.assign({}, this.param || params)
      }
    }
  },
  mounted () {
    this.onReset()
  }
}
</script>

<style>

</style>
